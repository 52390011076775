// 1. Width
// --------------
$global-width: 1440px;

// 2. Fonts
// --------------
$primary-font: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
//$secondary-font: 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;

// 3. Colours
// --------------
/// Colors used for buttons, callouts, links, etc. There must always be a color called `primary`.
/// @type Map
$foundation-palette: (
  primary: #1779ba,
  secondary: #767676,
  success: #3adb76,
  warning: #ffae00,
  alert: #cc4b37,
) !default;
$light-grey: #e6e6e6 !default;
$medium-grey: #cacaca !default;
$dark-grey: #8a8a8a !default;
$black: #0a0a0a !default;
$white: #fefefe !default;

$body-background: $white !default;
$body-font-color: $black !default;

// 4. Base
// -----------
$base-font-family: $primary-font !default;
$base-font-size: 100% !default;
$base-line-height: 1.5 !default;
$base-font-color: $black !default;
$base-font-weight: 400 !default;
$base-background-color: $white !default;

// 5. Headings
// --------------
$base-heading-font-family: $primary-font !default;
$base-heading-line-height: $base-line-height !default;
$base-h1-font-size: 3.2rem !default;
$base-h2-font-size: 2.6rem !default;
$base-h3-font-size: 2.2rem !default;
$base-h4-font-size: 1.8rem !default;
$base-h5-font-size: 1.6rem !default;
$base-h6-font-size: 1.4rem !default;

// 6. Breakpoints
// --------------
$breakpoints: (
  small: 0,
  smedium: 320px,
  medium: 768px,
  mlarge: 960px,
  large: 1280px,
  xlarge: $global-width,
  xxlarge: 1800px,
);
$breakpoint-classes: (small smedium medium mlarge large xlarge xxlarge);

// 7. The Grid
// -----------
$grid-row-width: $global-width;
$grid-column-count: 12;
$grid-column-gutter: (
  small: 20px,
  medium: 30px,
);
$grid-column-align-edge: true;
$grid-column-alias: "columns";
$block-grid-max: 8;

$grid-gutter: 16px !default;
$global-font-size: 100% !default;
$global-width: rem-calc(1200) !default;
$global-lineheight: 1.5 !default;

// 8. File Paths
// --------------
$public-path: "../";
$image-directory: "../img/";
$font-directory: "../fonts/";

// 9. Global
// --------------
$global-lineheight: 1.5;
$body-safe-padding: false;
$body-antialiased: true;
$global-margin: 1rem;
$global-padding: 1rem;
$global-position: 1rem;
$global-weight-normal: normal;
$global-weight-bold: bold;
$global-radius: 0;
$global-menu-padding: 0.7rem 1rem;
$global-menu-nested-margin: 1rem;
$global-text-direction: ltr;
$global-flexbox: true;
$global-prototype-breakpoints: false;
$global-button-cursor: auto;
$global-color-pick-contrast-tolerance: 0;
$print-transparent-backgrounds: true;
$print-hrefs: true;
$global-left: if($global-text-direction == rtl, right, left);
$global-right: if($global-text-direction == rtl, left, right);
