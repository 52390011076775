.padding-top-0-25rem { padding-top: 0.25rem; }
.padding-top-0-5rem { padding-top: 0.5rem; }
.padding-top-1rem { padding-top: 1rem; }
.padding-top-1-5rem { padding-top: 1.5rem; }
.padding-top-2rem { padding-top: 2rem; }
.padding-top-3rem { padding-top: 3rem; }
.padding-top-4rem { padding-top: 4rem; }
.padding-top-5rem { padding-top: 5rem; }
.padding-bottom-0-25rem { padding-bottom: 0.25rem; }
.padding-bottom-0-5rem { padding-bottom: 0.5rem; }
.padding-bottom-1rem { padding-bottom: 1rem; }
.padding-bottom-1-5rem { padding-bottom: 1.5rem; }
.padding-bottom-2rem { padding-bottom: 2rem; }
.padding-bottom-3rem { padding-bottom: 3rem; }
.padding-bottom-4rem { padding-bottom: 4rem; }
.padding-bottom-5rem { padding-bottom: 5rem; }
