table {
  text-align: left;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  margin: 0;
  border: 1px solid #e9e9e9
}
table td,
table th {
  padding: 1rem;
  border-bottom: 1px solid #e9e9e9;
  border-right: 1px solid #e9e9e9;
}

table tr:nth-child(2n) { background-color: #f6f8fa; }