@charset "UTF-8";
/* MAIN LESS FILE FOR COMPILING
--------------------------------------------------------------*/
/*--------------------------------------------------------------
1.0 Settings
--------------------------------------------------------------*/
/*--------------------------------------------------------------
2.0 Base
--------------------------------------------------------------*/
*, *:before, *:after {
  box-sizing: border-box;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

.sr {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1rem;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

table {
  text-align: left;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  margin: 0;
  border: 1px solid #e9e9e9;
}

table td,
table th {
  padding: 1rem;
  border-bottom: 1px solid #e9e9e9;
  border-right: 1px solid #e9e9e9;
}

table tr:nth-child(2n) {
  background-color: #f6f8fa;
}

body {
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-size: 100%;
  line-height: 1.5;
  color: #0a0a0a;
  font-weight: 400;
  background: #fefefe;
}

main, details {
  display: block;
}

a {
  background-color: transparent;
}

b, .b, strong, .strong {
  font-weight: 700;
}

em, .em {
  font-style: italic;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

small, .small {
  font-size: 0.8125rem;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

template, [hidden] {
  display: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  line-height: 1.5;
  margin: 0.5rem 0;
}

h1, .h1 {
  font-size: 3.2rem;
}

h2, .h2 {
  font-size: 2.6rem;
}

h3, .h3 {
  font-size: 2.2rem;
}

h4, .h4 {
  font-size: 1.8rem;
}

h5, .h5 {
  font-size: 1.6rem;
}

h6, .h6 {
  font-size: 1.4rem;
}

.padding-left-0-25rem {
  padding-left: 0.25rem;
}

.padding-left-0-5rem {
  padding-left: 0.5rem;
}

.padding-left-1rem {
  padding-left: 1rem;
}

.padding-left-1-5rem {
  padding-left: 1.5rem;
}

.padding-left-2rem {
  padding-left: 2rem;
}

.padding-left-3rem {
  padding-left: 3rem;
}

.padding-left-4rem {
  padding-left: 4rem;
}

.padding-left-5rem {
  padding-left: 5rem;
}

.padding-right-0-25rem {
  padding-right: 0.25rem;
}

.padding-right-0-5rem {
  padding-right: 0.5rem;
}

.padding-right-1rem {
  padding-right: 1rem;
}

.padding-right-1-5rem {
  padding-right: 1.5rem;
}

.padding-right-2rem {
  padding-right: 2rem;
}

.padding-right-3rem {
  padding-right: 3rem;
}

.padding-right-4rem {
  padding-right: 4rem;
}

.padding-right-5rem {
  padding-right: 5rem;
}

.padding-top-0-25rem {
  padding-top: 0.25rem;
}

.padding-top-0-5rem {
  padding-top: 0.5rem;
}

.padding-top-1rem {
  padding-top: 1rem;
}

.padding-top-1-5rem {
  padding-top: 1.5rem;
}

.padding-top-2rem {
  padding-top: 2rem;
}

.padding-top-3rem {
  padding-top: 3rem;
}

.padding-top-4rem {
  padding-top: 4rem;
}

.padding-top-5rem {
  padding-top: 5rem;
}

.padding-bottom-0-25rem {
  padding-bottom: 0.25rem;
}

.padding-bottom-0-5rem {
  padding-bottom: 0.5rem;
}

.padding-bottom-1rem {
  padding-bottom: 1rem;
}

.padding-bottom-1-5rem {
  padding-bottom: 1.5rem;
}

.padding-bottom-2rem {
  padding-bottom: 2rem;
}

.padding-bottom-3rem {
  padding-bottom: 3rem;
}

.padding-bottom-4rem {
  padding-bottom: 4rem;
}

.padding-bottom-5rem {
  padding-bottom: 5rem;
}

.padding-0 {
  padding: 0rem;
}

.padding-0-25rem {
  padding: 0.25rem;
}

.padding-0-5rem {
  padding: 0.5rem;
}

.padding-1rem {
  padding: 1rem;
}

.padding-1-5rem {
  padding: 1.5rem;
}

.padding-2rem {
  padding: 2rem;
}

.padding-3rem {
  padding: 3rem;
}

.padding-4rem {
  padding: 4rem;
}

.padding-5rem {
  padding: 5rem;
}

.none {
  display: none;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-align-items-top {
  align-items: center;
}

.flex-align-items-center {
  align-items: center;
}

.flex-align-items-bottom {
  align-items: center;
}

.flex-justify-left {
  justify-content: flex-start;
}

.flex-justify-center {
  justify-content: center;
}

.flex-justify-right {
  justify-content: flex-end;
}

.flex-space-around {
  justify-content: space-around;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column {
  flex-direction: column;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.sticky {
  position: sticky;
}

.no-select {
  user-select: none;
  cursor: default;
}

.font-100 {
  font-weight: 100;
}

.font-200 {
  font-weight: 200;
}

.font-300 {
  font-weight: 300;
}

.font-400 {
  font-weight: 400;
}

.font-500 {
  font-weight: 500;
}

.font-600 {
  font-weight: 600;
}

.font-700 {
  font-weight: 700;
}

.font-800 {
  font-weight: 800;
}

.font-900 {
  font-weight: 900;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

/*--------------------------------------------------------------
3.0 Foundation
--------------------------------------------------------------*/
/**
 * Foundation for Sites
 * Version 6.7.3
 * https://get.foundation
 * Licensed under MIT Open Source
 */
/* either include all foundation components…  //*/
/* or include specific foundation components //*/
/* include either foundation-grid… //*/
.row {
  max-width: 1440px;
  margin-right: auto;
  margin-left: auto;
}
.row::before, .row::after {
  display: table;
  content: " ";
  flex-basis: 0;
  order: 1;
}
.row::after {
  clear: both;
}
.row.collapse > .column, .row.collapse > .columns {
  padding-right: 0;
  padding-left: 0;
}
.row .row {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}
@media print, screen and (min-width: 20em) {
  .row .row {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
  }
}
@media print, screen and (min-width: 48em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media print, screen and (min-width: 60em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media print, screen and (min-width: 80em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media screen and (min-width: 112.5em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
.row .row.collapse {
  margin-right: 0;
  margin-left: 0;
}
.row.expanded {
  max-width: none;
}
.row.expanded .row {
  margin-right: auto;
  margin-left: auto;
}
.row:not(.expanded) .row {
  max-width: none;
}
.row.gutter-small > .column, .row.gutter-small > .columns {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}
.row.gutter-medium > .column, .row.gutter-medium > .columns {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}

.column, .columns {
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}
@media print, screen and (min-width: 48em) {
  .column, .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.column, .columns, .column:last-child:not(:first-child) {
  float: left;
  clear: none;
}
.column:last-child:not(:first-child), .columns:last-child:not(:first-child) {
  float: right;
}
.column.end:last-child:last-child, .end.columns:last-child:last-child {
  float: left;
}

.column.row.row, .row.row.columns {
  float: none;
}

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0;
}

.small-1 {
  width: 8.3333333333%;
}

.small-push-1 {
  position: relative;
  left: 8.3333333333%;
}

.small-pull-1 {
  position: relative;
  left: -8.3333333333%;
}

.small-offset-0 {
  margin-left: 0%;
}

.small-2 {
  width: 16.6666666667%;
}

.small-push-2 {
  position: relative;
  left: 16.6666666667%;
}

.small-pull-2 {
  position: relative;
  left: -16.6666666667%;
}

.small-offset-1 {
  margin-left: 8.3333333333%;
}

.small-3 {
  width: 25%;
}

.small-push-3 {
  position: relative;
  left: 25%;
}

.small-pull-3 {
  position: relative;
  left: -25%;
}

.small-offset-2 {
  margin-left: 16.6666666667%;
}

.small-4 {
  width: 33.3333333333%;
}

.small-push-4 {
  position: relative;
  left: 33.3333333333%;
}

.small-pull-4 {
  position: relative;
  left: -33.3333333333%;
}

.small-offset-3 {
  margin-left: 25%;
}

.small-5 {
  width: 41.6666666667%;
}

.small-push-5 {
  position: relative;
  left: 41.6666666667%;
}

.small-pull-5 {
  position: relative;
  left: -41.6666666667%;
}

.small-offset-4 {
  margin-left: 33.3333333333%;
}

.small-6 {
  width: 50%;
}

.small-push-6 {
  position: relative;
  left: 50%;
}

.small-pull-6 {
  position: relative;
  left: -50%;
}

.small-offset-5 {
  margin-left: 41.6666666667%;
}

.small-7 {
  width: 58.3333333333%;
}

.small-push-7 {
  position: relative;
  left: 58.3333333333%;
}

.small-pull-7 {
  position: relative;
  left: -58.3333333333%;
}

.small-offset-6 {
  margin-left: 50%;
}

.small-8 {
  width: 66.6666666667%;
}

.small-push-8 {
  position: relative;
  left: 66.6666666667%;
}

.small-pull-8 {
  position: relative;
  left: -66.6666666667%;
}

.small-offset-7 {
  margin-left: 58.3333333333%;
}

.small-9 {
  width: 75%;
}

.small-push-9 {
  position: relative;
  left: 75%;
}

.small-pull-9 {
  position: relative;
  left: -75%;
}

.small-offset-8 {
  margin-left: 66.6666666667%;
}

.small-10 {
  width: 83.3333333333%;
}

.small-push-10 {
  position: relative;
  left: 83.3333333333%;
}

.small-pull-10 {
  position: relative;
  left: -83.3333333333%;
}

.small-offset-9 {
  margin-left: 75%;
}

.small-11 {
  width: 91.6666666667%;
}

.small-push-11 {
  position: relative;
  left: 91.6666666667%;
}

.small-pull-11 {
  position: relative;
  left: -91.6666666667%;
}

.small-offset-10 {
  margin-left: 83.3333333333%;
}

.small-12 {
  width: 100%;
}

.small-offset-11 {
  margin-left: 91.6666666667%;
}

.small-up-1 > .column, .small-up-1 > .columns {
  float: left;
  width: 100%;
}
.small-up-1 > .column:nth-of-type(1n), .small-up-1 > .columns:nth-of-type(1n) {
  clear: none;
}
.small-up-1 > .column:nth-of-type(1n+1), .small-up-1 > .columns:nth-of-type(1n+1) {
  clear: both;
}
.small-up-1 > .column:last-child, .small-up-1 > .columns:last-child {
  float: left;
}

.small-up-2 > .column, .small-up-2 > .columns {
  float: left;
  width: 50%;
}
.small-up-2 > .column:nth-of-type(1n), .small-up-2 > .columns:nth-of-type(1n) {
  clear: none;
}
.small-up-2 > .column:nth-of-type(2n+1), .small-up-2 > .columns:nth-of-type(2n+1) {
  clear: both;
}
.small-up-2 > .column:last-child, .small-up-2 > .columns:last-child {
  float: left;
}

.small-up-3 > .column, .small-up-3 > .columns {
  float: left;
  width: 33.3333333333%;
}
.small-up-3 > .column:nth-of-type(1n), .small-up-3 > .columns:nth-of-type(1n) {
  clear: none;
}
.small-up-3 > .column:nth-of-type(3n+1), .small-up-3 > .columns:nth-of-type(3n+1) {
  clear: both;
}
.small-up-3 > .column:last-child, .small-up-3 > .columns:last-child {
  float: left;
}

.small-up-4 > .column, .small-up-4 > .columns {
  float: left;
  width: 25%;
}
.small-up-4 > .column:nth-of-type(1n), .small-up-4 > .columns:nth-of-type(1n) {
  clear: none;
}
.small-up-4 > .column:nth-of-type(4n+1), .small-up-4 > .columns:nth-of-type(4n+1) {
  clear: both;
}
.small-up-4 > .column:last-child, .small-up-4 > .columns:last-child {
  float: left;
}

.small-up-5 > .column, .small-up-5 > .columns {
  float: left;
  width: 20%;
}
.small-up-5 > .column:nth-of-type(1n), .small-up-5 > .columns:nth-of-type(1n) {
  clear: none;
}
.small-up-5 > .column:nth-of-type(5n+1), .small-up-5 > .columns:nth-of-type(5n+1) {
  clear: both;
}
.small-up-5 > .column:last-child, .small-up-5 > .columns:last-child {
  float: left;
}

.small-up-6 > .column, .small-up-6 > .columns {
  float: left;
  width: 16.6666666667%;
}
.small-up-6 > .column:nth-of-type(1n), .small-up-6 > .columns:nth-of-type(1n) {
  clear: none;
}
.small-up-6 > .column:nth-of-type(6n+1), .small-up-6 > .columns:nth-of-type(6n+1) {
  clear: both;
}
.small-up-6 > .column:last-child, .small-up-6 > .columns:last-child {
  float: left;
}

.small-up-7 > .column, .small-up-7 > .columns {
  float: left;
  width: 14.2857142857%;
}
.small-up-7 > .column:nth-of-type(1n), .small-up-7 > .columns:nth-of-type(1n) {
  clear: none;
}
.small-up-7 > .column:nth-of-type(7n+1), .small-up-7 > .columns:nth-of-type(7n+1) {
  clear: both;
}
.small-up-7 > .column:last-child, .small-up-7 > .columns:last-child {
  float: left;
}

.small-up-8 > .column, .small-up-8 > .columns {
  float: left;
  width: 12.5%;
}
.small-up-8 > .column:nth-of-type(1n), .small-up-8 > .columns:nth-of-type(1n) {
  clear: none;
}
.small-up-8 > .column:nth-of-type(8n+1), .small-up-8 > .columns:nth-of-type(8n+1) {
  clear: both;
}
.small-up-8 > .column:last-child, .small-up-8 > .columns:last-child {
  float: left;
}

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0;
}
.small-collapse .row {
  margin-right: 0;
  margin-left: 0;
}

.expanded.row .small-collapse.row {
  margin-right: 0;
  margin-left: 0;
}

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}

.small-centered {
  margin-right: auto;
  margin-left: auto;
}
.small-centered, .small-centered:last-child:not(:first-child) {
  float: none;
  clear: both;
}

.small-uncentered, .small-push-0, .small-pull-0 {
  position: static;
  margin-right: 0;
  margin-left: 0;
}
.small-uncentered, .small-uncentered:last-child:not(:first-child), .small-push-0, .small-push-0:last-child:not(:first-child), .small-pull-0, .small-pull-0:last-child:not(:first-child) {
  float: left;
  clear: none;
}
.small-uncentered:last-child:not(:first-child), .small-push-0:last-child:not(:first-child), .small-pull-0:last-child:not(:first-child) {
  float: right;
}

@media print, screen and (min-width: 20em) {
  .smedium-1 {
    width: 8.3333333333%;
  }

  .smedium-push-1 {
    position: relative;
    left: 8.3333333333%;
  }

  .smedium-pull-1 {
    position: relative;
    left: -8.3333333333%;
  }

  .smedium-offset-0 {
    margin-left: 0%;
  }

  .smedium-2 {
    width: 16.6666666667%;
  }

  .smedium-push-2 {
    position: relative;
    left: 16.6666666667%;
  }

  .smedium-pull-2 {
    position: relative;
    left: -16.6666666667%;
  }

  .smedium-offset-1 {
    margin-left: 8.3333333333%;
  }

  .smedium-3 {
    width: 25%;
  }

  .smedium-push-3 {
    position: relative;
    left: 25%;
  }

  .smedium-pull-3 {
    position: relative;
    left: -25%;
  }

  .smedium-offset-2 {
    margin-left: 16.6666666667%;
  }

  .smedium-4 {
    width: 33.3333333333%;
  }

  .smedium-push-4 {
    position: relative;
    left: 33.3333333333%;
  }

  .smedium-pull-4 {
    position: relative;
    left: -33.3333333333%;
  }

  .smedium-offset-3 {
    margin-left: 25%;
  }

  .smedium-5 {
    width: 41.6666666667%;
  }

  .smedium-push-5 {
    position: relative;
    left: 41.6666666667%;
  }

  .smedium-pull-5 {
    position: relative;
    left: -41.6666666667%;
  }

  .smedium-offset-4 {
    margin-left: 33.3333333333%;
  }

  .smedium-6 {
    width: 50%;
  }

  .smedium-push-6 {
    position: relative;
    left: 50%;
  }

  .smedium-pull-6 {
    position: relative;
    left: -50%;
  }

  .smedium-offset-5 {
    margin-left: 41.6666666667%;
  }

  .smedium-7 {
    width: 58.3333333333%;
  }

  .smedium-push-7 {
    position: relative;
    left: 58.3333333333%;
  }

  .smedium-pull-7 {
    position: relative;
    left: -58.3333333333%;
  }

  .smedium-offset-6 {
    margin-left: 50%;
  }

  .smedium-8 {
    width: 66.6666666667%;
  }

  .smedium-push-8 {
    position: relative;
    left: 66.6666666667%;
  }

  .smedium-pull-8 {
    position: relative;
    left: -66.6666666667%;
  }

  .smedium-offset-7 {
    margin-left: 58.3333333333%;
  }

  .smedium-9 {
    width: 75%;
  }

  .smedium-push-9 {
    position: relative;
    left: 75%;
  }

  .smedium-pull-9 {
    position: relative;
    left: -75%;
  }

  .smedium-offset-8 {
    margin-left: 66.6666666667%;
  }

  .smedium-10 {
    width: 83.3333333333%;
  }

  .smedium-push-10 {
    position: relative;
    left: 83.3333333333%;
  }

  .smedium-pull-10 {
    position: relative;
    left: -83.3333333333%;
  }

  .smedium-offset-9 {
    margin-left: 75%;
  }

  .smedium-11 {
    width: 91.6666666667%;
  }

  .smedium-push-11 {
    position: relative;
    left: 91.6666666667%;
  }

  .smedium-pull-11 {
    position: relative;
    left: -91.6666666667%;
  }

  .smedium-offset-10 {
    margin-left: 83.3333333333%;
  }

  .smedium-12 {
    width: 100%;
  }

  .smedium-offset-11 {
    margin-left: 91.6666666667%;
  }

  .smedium-up-1 > .column, .smedium-up-1 > .columns {
    float: left;
    width: 100%;
  }
  .smedium-up-1 > .column:nth-of-type(1n), .smedium-up-1 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .smedium-up-1 > .column:nth-of-type(1n+1), .smedium-up-1 > .columns:nth-of-type(1n+1) {
    clear: both;
  }
  .smedium-up-1 > .column:last-child, .smedium-up-1 > .columns:last-child {
    float: left;
  }

  .smedium-up-2 > .column, .smedium-up-2 > .columns {
    float: left;
    width: 50%;
  }
  .smedium-up-2 > .column:nth-of-type(1n), .smedium-up-2 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .smedium-up-2 > .column:nth-of-type(2n+1), .smedium-up-2 > .columns:nth-of-type(2n+1) {
    clear: both;
  }
  .smedium-up-2 > .column:last-child, .smedium-up-2 > .columns:last-child {
    float: left;
  }

  .smedium-up-3 > .column, .smedium-up-3 > .columns {
    float: left;
    width: 33.3333333333%;
  }
  .smedium-up-3 > .column:nth-of-type(1n), .smedium-up-3 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .smedium-up-3 > .column:nth-of-type(3n+1), .smedium-up-3 > .columns:nth-of-type(3n+1) {
    clear: both;
  }
  .smedium-up-3 > .column:last-child, .smedium-up-3 > .columns:last-child {
    float: left;
  }

  .smedium-up-4 > .column, .smedium-up-4 > .columns {
    float: left;
    width: 25%;
  }
  .smedium-up-4 > .column:nth-of-type(1n), .smedium-up-4 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .smedium-up-4 > .column:nth-of-type(4n+1), .smedium-up-4 > .columns:nth-of-type(4n+1) {
    clear: both;
  }
  .smedium-up-4 > .column:last-child, .smedium-up-4 > .columns:last-child {
    float: left;
  }

  .smedium-up-5 > .column, .smedium-up-5 > .columns {
    float: left;
    width: 20%;
  }
  .smedium-up-5 > .column:nth-of-type(1n), .smedium-up-5 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .smedium-up-5 > .column:nth-of-type(5n+1), .smedium-up-5 > .columns:nth-of-type(5n+1) {
    clear: both;
  }
  .smedium-up-5 > .column:last-child, .smedium-up-5 > .columns:last-child {
    float: left;
  }

  .smedium-up-6 > .column, .smedium-up-6 > .columns {
    float: left;
    width: 16.6666666667%;
  }
  .smedium-up-6 > .column:nth-of-type(1n), .smedium-up-6 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .smedium-up-6 > .column:nth-of-type(6n+1), .smedium-up-6 > .columns:nth-of-type(6n+1) {
    clear: both;
  }
  .smedium-up-6 > .column:last-child, .smedium-up-6 > .columns:last-child {
    float: left;
  }

  .smedium-up-7 > .column, .smedium-up-7 > .columns {
    float: left;
    width: 14.2857142857%;
  }
  .smedium-up-7 > .column:nth-of-type(1n), .smedium-up-7 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .smedium-up-7 > .column:nth-of-type(7n+1), .smedium-up-7 > .columns:nth-of-type(7n+1) {
    clear: both;
  }
  .smedium-up-7 > .column:last-child, .smedium-up-7 > .columns:last-child {
    float: left;
  }

  .smedium-up-8 > .column, .smedium-up-8 > .columns {
    float: left;
    width: 12.5%;
  }
  .smedium-up-8 > .column:nth-of-type(1n), .smedium-up-8 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .smedium-up-8 > .column:nth-of-type(8n+1), .smedium-up-8 > .columns:nth-of-type(8n+1) {
    clear: both;
  }
  .smedium-up-8 > .column:last-child, .smedium-up-8 > .columns:last-child {
    float: left;
  }

  .smedium-collapse > .column, .smedium-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .smedium-collapse .row {
    margin-right: 0;
    margin-left: 0;
  }

  .expanded.row .smedium-collapse.row {
    margin-right: 0;
    margin-left: 0;
  }

  .smedium-uncollapse > .column, .smedium-uncollapse > .columns {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }

  .smedium-centered {
    margin-right: auto;
    margin-left: auto;
  }
  .smedium-centered, .smedium-centered:last-child:not(:first-child) {
    float: none;
    clear: both;
  }

  .smedium-uncentered, .smedium-push-0, .smedium-pull-0 {
    position: static;
    margin-right: 0;
    margin-left: 0;
  }
  .smedium-uncentered, .smedium-uncentered:last-child:not(:first-child), .smedium-push-0, .smedium-push-0:last-child:not(:first-child), .smedium-pull-0, .smedium-pull-0:last-child:not(:first-child) {
    float: left;
    clear: none;
  }
  .smedium-uncentered:last-child:not(:first-child), .smedium-push-0:last-child:not(:first-child), .smedium-pull-0:last-child:not(:first-child) {
    float: right;
  }
}
@media print, screen and (min-width: 48em) {
  .medium-1 {
    width: 8.3333333333%;
  }

  .medium-push-1 {
    position: relative;
    left: 8.3333333333%;
  }

  .medium-pull-1 {
    position: relative;
    left: -8.3333333333%;
  }

  .medium-offset-0 {
    margin-left: 0%;
  }

  .medium-2 {
    width: 16.6666666667%;
  }

  .medium-push-2 {
    position: relative;
    left: 16.6666666667%;
  }

  .medium-pull-2 {
    position: relative;
    left: -16.6666666667%;
  }

  .medium-offset-1 {
    margin-left: 8.3333333333%;
  }

  .medium-3 {
    width: 25%;
  }

  .medium-push-3 {
    position: relative;
    left: 25%;
  }

  .medium-pull-3 {
    position: relative;
    left: -25%;
  }

  .medium-offset-2 {
    margin-left: 16.6666666667%;
  }

  .medium-4 {
    width: 33.3333333333%;
  }

  .medium-push-4 {
    position: relative;
    left: 33.3333333333%;
  }

  .medium-pull-4 {
    position: relative;
    left: -33.3333333333%;
  }

  .medium-offset-3 {
    margin-left: 25%;
  }

  .medium-5 {
    width: 41.6666666667%;
  }

  .medium-push-5 {
    position: relative;
    left: 41.6666666667%;
  }

  .medium-pull-5 {
    position: relative;
    left: -41.6666666667%;
  }

  .medium-offset-4 {
    margin-left: 33.3333333333%;
  }

  .medium-6 {
    width: 50%;
  }

  .medium-push-6 {
    position: relative;
    left: 50%;
  }

  .medium-pull-6 {
    position: relative;
    left: -50%;
  }

  .medium-offset-5 {
    margin-left: 41.6666666667%;
  }

  .medium-7 {
    width: 58.3333333333%;
  }

  .medium-push-7 {
    position: relative;
    left: 58.3333333333%;
  }

  .medium-pull-7 {
    position: relative;
    left: -58.3333333333%;
  }

  .medium-offset-6 {
    margin-left: 50%;
  }

  .medium-8 {
    width: 66.6666666667%;
  }

  .medium-push-8 {
    position: relative;
    left: 66.6666666667%;
  }

  .medium-pull-8 {
    position: relative;
    left: -66.6666666667%;
  }

  .medium-offset-7 {
    margin-left: 58.3333333333%;
  }

  .medium-9 {
    width: 75%;
  }

  .medium-push-9 {
    position: relative;
    left: 75%;
  }

  .medium-pull-9 {
    position: relative;
    left: -75%;
  }

  .medium-offset-8 {
    margin-left: 66.6666666667%;
  }

  .medium-10 {
    width: 83.3333333333%;
  }

  .medium-push-10 {
    position: relative;
    left: 83.3333333333%;
  }

  .medium-pull-10 {
    position: relative;
    left: -83.3333333333%;
  }

  .medium-offset-9 {
    margin-left: 75%;
  }

  .medium-11 {
    width: 91.6666666667%;
  }

  .medium-push-11 {
    position: relative;
    left: 91.6666666667%;
  }

  .medium-pull-11 {
    position: relative;
    left: -91.6666666667%;
  }

  .medium-offset-10 {
    margin-left: 83.3333333333%;
  }

  .medium-12 {
    width: 100%;
  }

  .medium-offset-11 {
    margin-left: 91.6666666667%;
  }

  .medium-up-1 > .column, .medium-up-1 > .columns {
    float: left;
    width: 100%;
  }
  .medium-up-1 > .column:nth-of-type(1n), .medium-up-1 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-1 > .column:nth-of-type(1n+1), .medium-up-1 > .columns:nth-of-type(1n+1) {
    clear: both;
  }
  .medium-up-1 > .column:last-child, .medium-up-1 > .columns:last-child {
    float: left;
  }

  .medium-up-2 > .column, .medium-up-2 > .columns {
    float: left;
    width: 50%;
  }
  .medium-up-2 > .column:nth-of-type(1n), .medium-up-2 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-2 > .column:nth-of-type(2n+1), .medium-up-2 > .columns:nth-of-type(2n+1) {
    clear: both;
  }
  .medium-up-2 > .column:last-child, .medium-up-2 > .columns:last-child {
    float: left;
  }

  .medium-up-3 > .column, .medium-up-3 > .columns {
    float: left;
    width: 33.3333333333%;
  }
  .medium-up-3 > .column:nth-of-type(1n), .medium-up-3 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-3 > .column:nth-of-type(3n+1), .medium-up-3 > .columns:nth-of-type(3n+1) {
    clear: both;
  }
  .medium-up-3 > .column:last-child, .medium-up-3 > .columns:last-child {
    float: left;
  }

  .medium-up-4 > .column, .medium-up-4 > .columns {
    float: left;
    width: 25%;
  }
  .medium-up-4 > .column:nth-of-type(1n), .medium-up-4 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-4 > .column:nth-of-type(4n+1), .medium-up-4 > .columns:nth-of-type(4n+1) {
    clear: both;
  }
  .medium-up-4 > .column:last-child, .medium-up-4 > .columns:last-child {
    float: left;
  }

  .medium-up-5 > .column, .medium-up-5 > .columns {
    float: left;
    width: 20%;
  }
  .medium-up-5 > .column:nth-of-type(1n), .medium-up-5 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-5 > .column:nth-of-type(5n+1), .medium-up-5 > .columns:nth-of-type(5n+1) {
    clear: both;
  }
  .medium-up-5 > .column:last-child, .medium-up-5 > .columns:last-child {
    float: left;
  }

  .medium-up-6 > .column, .medium-up-6 > .columns {
    float: left;
    width: 16.6666666667%;
  }
  .medium-up-6 > .column:nth-of-type(1n), .medium-up-6 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-6 > .column:nth-of-type(6n+1), .medium-up-6 > .columns:nth-of-type(6n+1) {
    clear: both;
  }
  .medium-up-6 > .column:last-child, .medium-up-6 > .columns:last-child {
    float: left;
  }

  .medium-up-7 > .column, .medium-up-7 > .columns {
    float: left;
    width: 14.2857142857%;
  }
  .medium-up-7 > .column:nth-of-type(1n), .medium-up-7 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-7 > .column:nth-of-type(7n+1), .medium-up-7 > .columns:nth-of-type(7n+1) {
    clear: both;
  }
  .medium-up-7 > .column:last-child, .medium-up-7 > .columns:last-child {
    float: left;
  }

  .medium-up-8 > .column, .medium-up-8 > .columns {
    float: left;
    width: 12.5%;
  }
  .medium-up-8 > .column:nth-of-type(1n), .medium-up-8 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-8 > .column:nth-of-type(8n+1), .medium-up-8 > .columns:nth-of-type(8n+1) {
    clear: both;
  }
  .medium-up-8 > .column:last-child, .medium-up-8 > .columns:last-child {
    float: left;
  }

  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .medium-collapse .row {
    margin-right: 0;
    margin-left: 0;
  }

  .expanded.row .medium-collapse.row {
    margin-right: 0;
    margin-left: 0;
  }

  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }

  .medium-centered {
    margin-right: auto;
    margin-left: auto;
  }
  .medium-centered, .medium-centered:last-child:not(:first-child) {
    float: none;
    clear: both;
  }

  .medium-uncentered, .medium-push-0, .medium-pull-0 {
    position: static;
    margin-right: 0;
    margin-left: 0;
  }
  .medium-uncentered, .medium-uncentered:last-child:not(:first-child), .medium-push-0, .medium-push-0:last-child:not(:first-child), .medium-pull-0, .medium-pull-0:last-child:not(:first-child) {
    float: left;
    clear: none;
  }
  .medium-uncentered:last-child:not(:first-child), .medium-push-0:last-child:not(:first-child), .medium-pull-0:last-child:not(:first-child) {
    float: right;
  }
}
@media print, screen and (min-width: 60em) {
  .mlarge-1 {
    width: 8.3333333333%;
  }

  .mlarge-push-1 {
    position: relative;
    left: 8.3333333333%;
  }

  .mlarge-pull-1 {
    position: relative;
    left: -8.3333333333%;
  }

  .mlarge-offset-0 {
    margin-left: 0%;
  }

  .mlarge-2 {
    width: 16.6666666667%;
  }

  .mlarge-push-2 {
    position: relative;
    left: 16.6666666667%;
  }

  .mlarge-pull-2 {
    position: relative;
    left: -16.6666666667%;
  }

  .mlarge-offset-1 {
    margin-left: 8.3333333333%;
  }

  .mlarge-3 {
    width: 25%;
  }

  .mlarge-push-3 {
    position: relative;
    left: 25%;
  }

  .mlarge-pull-3 {
    position: relative;
    left: -25%;
  }

  .mlarge-offset-2 {
    margin-left: 16.6666666667%;
  }

  .mlarge-4 {
    width: 33.3333333333%;
  }

  .mlarge-push-4 {
    position: relative;
    left: 33.3333333333%;
  }

  .mlarge-pull-4 {
    position: relative;
    left: -33.3333333333%;
  }

  .mlarge-offset-3 {
    margin-left: 25%;
  }

  .mlarge-5 {
    width: 41.6666666667%;
  }

  .mlarge-push-5 {
    position: relative;
    left: 41.6666666667%;
  }

  .mlarge-pull-5 {
    position: relative;
    left: -41.6666666667%;
  }

  .mlarge-offset-4 {
    margin-left: 33.3333333333%;
  }

  .mlarge-6 {
    width: 50%;
  }

  .mlarge-push-6 {
    position: relative;
    left: 50%;
  }

  .mlarge-pull-6 {
    position: relative;
    left: -50%;
  }

  .mlarge-offset-5 {
    margin-left: 41.6666666667%;
  }

  .mlarge-7 {
    width: 58.3333333333%;
  }

  .mlarge-push-7 {
    position: relative;
    left: 58.3333333333%;
  }

  .mlarge-pull-7 {
    position: relative;
    left: -58.3333333333%;
  }

  .mlarge-offset-6 {
    margin-left: 50%;
  }

  .mlarge-8 {
    width: 66.6666666667%;
  }

  .mlarge-push-8 {
    position: relative;
    left: 66.6666666667%;
  }

  .mlarge-pull-8 {
    position: relative;
    left: -66.6666666667%;
  }

  .mlarge-offset-7 {
    margin-left: 58.3333333333%;
  }

  .mlarge-9 {
    width: 75%;
  }

  .mlarge-push-9 {
    position: relative;
    left: 75%;
  }

  .mlarge-pull-9 {
    position: relative;
    left: -75%;
  }

  .mlarge-offset-8 {
    margin-left: 66.6666666667%;
  }

  .mlarge-10 {
    width: 83.3333333333%;
  }

  .mlarge-push-10 {
    position: relative;
    left: 83.3333333333%;
  }

  .mlarge-pull-10 {
    position: relative;
    left: -83.3333333333%;
  }

  .mlarge-offset-9 {
    margin-left: 75%;
  }

  .mlarge-11 {
    width: 91.6666666667%;
  }

  .mlarge-push-11 {
    position: relative;
    left: 91.6666666667%;
  }

  .mlarge-pull-11 {
    position: relative;
    left: -91.6666666667%;
  }

  .mlarge-offset-10 {
    margin-left: 83.3333333333%;
  }

  .mlarge-12 {
    width: 100%;
  }

  .mlarge-offset-11 {
    margin-left: 91.6666666667%;
  }

  .mlarge-up-1 > .column, .mlarge-up-1 > .columns {
    float: left;
    width: 100%;
  }
  .mlarge-up-1 > .column:nth-of-type(1n), .mlarge-up-1 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .mlarge-up-1 > .column:nth-of-type(1n+1), .mlarge-up-1 > .columns:nth-of-type(1n+1) {
    clear: both;
  }
  .mlarge-up-1 > .column:last-child, .mlarge-up-1 > .columns:last-child {
    float: left;
  }

  .mlarge-up-2 > .column, .mlarge-up-2 > .columns {
    float: left;
    width: 50%;
  }
  .mlarge-up-2 > .column:nth-of-type(1n), .mlarge-up-2 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .mlarge-up-2 > .column:nth-of-type(2n+1), .mlarge-up-2 > .columns:nth-of-type(2n+1) {
    clear: both;
  }
  .mlarge-up-2 > .column:last-child, .mlarge-up-2 > .columns:last-child {
    float: left;
  }

  .mlarge-up-3 > .column, .mlarge-up-3 > .columns {
    float: left;
    width: 33.3333333333%;
  }
  .mlarge-up-3 > .column:nth-of-type(1n), .mlarge-up-3 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .mlarge-up-3 > .column:nth-of-type(3n+1), .mlarge-up-3 > .columns:nth-of-type(3n+1) {
    clear: both;
  }
  .mlarge-up-3 > .column:last-child, .mlarge-up-3 > .columns:last-child {
    float: left;
  }

  .mlarge-up-4 > .column, .mlarge-up-4 > .columns {
    float: left;
    width: 25%;
  }
  .mlarge-up-4 > .column:nth-of-type(1n), .mlarge-up-4 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .mlarge-up-4 > .column:nth-of-type(4n+1), .mlarge-up-4 > .columns:nth-of-type(4n+1) {
    clear: both;
  }
  .mlarge-up-4 > .column:last-child, .mlarge-up-4 > .columns:last-child {
    float: left;
  }

  .mlarge-up-5 > .column, .mlarge-up-5 > .columns {
    float: left;
    width: 20%;
  }
  .mlarge-up-5 > .column:nth-of-type(1n), .mlarge-up-5 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .mlarge-up-5 > .column:nth-of-type(5n+1), .mlarge-up-5 > .columns:nth-of-type(5n+1) {
    clear: both;
  }
  .mlarge-up-5 > .column:last-child, .mlarge-up-5 > .columns:last-child {
    float: left;
  }

  .mlarge-up-6 > .column, .mlarge-up-6 > .columns {
    float: left;
    width: 16.6666666667%;
  }
  .mlarge-up-6 > .column:nth-of-type(1n), .mlarge-up-6 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .mlarge-up-6 > .column:nth-of-type(6n+1), .mlarge-up-6 > .columns:nth-of-type(6n+1) {
    clear: both;
  }
  .mlarge-up-6 > .column:last-child, .mlarge-up-6 > .columns:last-child {
    float: left;
  }

  .mlarge-up-7 > .column, .mlarge-up-7 > .columns {
    float: left;
    width: 14.2857142857%;
  }
  .mlarge-up-7 > .column:nth-of-type(1n), .mlarge-up-7 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .mlarge-up-7 > .column:nth-of-type(7n+1), .mlarge-up-7 > .columns:nth-of-type(7n+1) {
    clear: both;
  }
  .mlarge-up-7 > .column:last-child, .mlarge-up-7 > .columns:last-child {
    float: left;
  }

  .mlarge-up-8 > .column, .mlarge-up-8 > .columns {
    float: left;
    width: 12.5%;
  }
  .mlarge-up-8 > .column:nth-of-type(1n), .mlarge-up-8 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .mlarge-up-8 > .column:nth-of-type(8n+1), .mlarge-up-8 > .columns:nth-of-type(8n+1) {
    clear: both;
  }
  .mlarge-up-8 > .column:last-child, .mlarge-up-8 > .columns:last-child {
    float: left;
  }

  .mlarge-collapse > .column, .mlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .mlarge-collapse .row {
    margin-right: 0;
    margin-left: 0;
  }

  .expanded.row .mlarge-collapse.row {
    margin-right: 0;
    margin-left: 0;
  }

  .mlarge-uncollapse > .column, .mlarge-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }

  .mlarge-centered {
    margin-right: auto;
    margin-left: auto;
  }
  .mlarge-centered, .mlarge-centered:last-child:not(:first-child) {
    float: none;
    clear: both;
  }

  .mlarge-uncentered, .mlarge-push-0, .mlarge-pull-0 {
    position: static;
    margin-right: 0;
    margin-left: 0;
  }
  .mlarge-uncentered, .mlarge-uncentered:last-child:not(:first-child), .mlarge-push-0, .mlarge-push-0:last-child:not(:first-child), .mlarge-pull-0, .mlarge-pull-0:last-child:not(:first-child) {
    float: left;
    clear: none;
  }
  .mlarge-uncentered:last-child:not(:first-child), .mlarge-push-0:last-child:not(:first-child), .mlarge-pull-0:last-child:not(:first-child) {
    float: right;
  }
}
@media print, screen and (min-width: 80em) {
  .large-1 {
    width: 8.3333333333%;
  }

  .large-push-1 {
    position: relative;
    left: 8.3333333333%;
  }

  .large-pull-1 {
    position: relative;
    left: -8.3333333333%;
  }

  .large-offset-0 {
    margin-left: 0%;
  }

  .large-2 {
    width: 16.6666666667%;
  }

  .large-push-2 {
    position: relative;
    left: 16.6666666667%;
  }

  .large-pull-2 {
    position: relative;
    left: -16.6666666667%;
  }

  .large-offset-1 {
    margin-left: 8.3333333333%;
  }

  .large-3 {
    width: 25%;
  }

  .large-push-3 {
    position: relative;
    left: 25%;
  }

  .large-pull-3 {
    position: relative;
    left: -25%;
  }

  .large-offset-2 {
    margin-left: 16.6666666667%;
  }

  .large-4 {
    width: 33.3333333333%;
  }

  .large-push-4 {
    position: relative;
    left: 33.3333333333%;
  }

  .large-pull-4 {
    position: relative;
    left: -33.3333333333%;
  }

  .large-offset-3 {
    margin-left: 25%;
  }

  .large-5 {
    width: 41.6666666667%;
  }

  .large-push-5 {
    position: relative;
    left: 41.6666666667%;
  }

  .large-pull-5 {
    position: relative;
    left: -41.6666666667%;
  }

  .large-offset-4 {
    margin-left: 33.3333333333%;
  }

  .large-6 {
    width: 50%;
  }

  .large-push-6 {
    position: relative;
    left: 50%;
  }

  .large-pull-6 {
    position: relative;
    left: -50%;
  }

  .large-offset-5 {
    margin-left: 41.6666666667%;
  }

  .large-7 {
    width: 58.3333333333%;
  }

  .large-push-7 {
    position: relative;
    left: 58.3333333333%;
  }

  .large-pull-7 {
    position: relative;
    left: -58.3333333333%;
  }

  .large-offset-6 {
    margin-left: 50%;
  }

  .large-8 {
    width: 66.6666666667%;
  }

  .large-push-8 {
    position: relative;
    left: 66.6666666667%;
  }

  .large-pull-8 {
    position: relative;
    left: -66.6666666667%;
  }

  .large-offset-7 {
    margin-left: 58.3333333333%;
  }

  .large-9 {
    width: 75%;
  }

  .large-push-9 {
    position: relative;
    left: 75%;
  }

  .large-pull-9 {
    position: relative;
    left: -75%;
  }

  .large-offset-8 {
    margin-left: 66.6666666667%;
  }

  .large-10 {
    width: 83.3333333333%;
  }

  .large-push-10 {
    position: relative;
    left: 83.3333333333%;
  }

  .large-pull-10 {
    position: relative;
    left: -83.3333333333%;
  }

  .large-offset-9 {
    margin-left: 75%;
  }

  .large-11 {
    width: 91.6666666667%;
  }

  .large-push-11 {
    position: relative;
    left: 91.6666666667%;
  }

  .large-pull-11 {
    position: relative;
    left: -91.6666666667%;
  }

  .large-offset-10 {
    margin-left: 83.3333333333%;
  }

  .large-12 {
    width: 100%;
  }

  .large-offset-11 {
    margin-left: 91.6666666667%;
  }

  .large-up-1 > .column, .large-up-1 > .columns {
    float: left;
    width: 100%;
  }
  .large-up-1 > .column:nth-of-type(1n), .large-up-1 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-1 > .column:nth-of-type(1n+1), .large-up-1 > .columns:nth-of-type(1n+1) {
    clear: both;
  }
  .large-up-1 > .column:last-child, .large-up-1 > .columns:last-child {
    float: left;
  }

  .large-up-2 > .column, .large-up-2 > .columns {
    float: left;
    width: 50%;
  }
  .large-up-2 > .column:nth-of-type(1n), .large-up-2 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-2 > .column:nth-of-type(2n+1), .large-up-2 > .columns:nth-of-type(2n+1) {
    clear: both;
  }
  .large-up-2 > .column:last-child, .large-up-2 > .columns:last-child {
    float: left;
  }

  .large-up-3 > .column, .large-up-3 > .columns {
    float: left;
    width: 33.3333333333%;
  }
  .large-up-3 > .column:nth-of-type(1n), .large-up-3 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-3 > .column:nth-of-type(3n+1), .large-up-3 > .columns:nth-of-type(3n+1) {
    clear: both;
  }
  .large-up-3 > .column:last-child, .large-up-3 > .columns:last-child {
    float: left;
  }

  .large-up-4 > .column, .large-up-4 > .columns {
    float: left;
    width: 25%;
  }
  .large-up-4 > .column:nth-of-type(1n), .large-up-4 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-4 > .column:nth-of-type(4n+1), .large-up-4 > .columns:nth-of-type(4n+1) {
    clear: both;
  }
  .large-up-4 > .column:last-child, .large-up-4 > .columns:last-child {
    float: left;
  }

  .large-up-5 > .column, .large-up-5 > .columns {
    float: left;
    width: 20%;
  }
  .large-up-5 > .column:nth-of-type(1n), .large-up-5 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-5 > .column:nth-of-type(5n+1), .large-up-5 > .columns:nth-of-type(5n+1) {
    clear: both;
  }
  .large-up-5 > .column:last-child, .large-up-5 > .columns:last-child {
    float: left;
  }

  .large-up-6 > .column, .large-up-6 > .columns {
    float: left;
    width: 16.6666666667%;
  }
  .large-up-6 > .column:nth-of-type(1n), .large-up-6 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-6 > .column:nth-of-type(6n+1), .large-up-6 > .columns:nth-of-type(6n+1) {
    clear: both;
  }
  .large-up-6 > .column:last-child, .large-up-6 > .columns:last-child {
    float: left;
  }

  .large-up-7 > .column, .large-up-7 > .columns {
    float: left;
    width: 14.2857142857%;
  }
  .large-up-7 > .column:nth-of-type(1n), .large-up-7 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-7 > .column:nth-of-type(7n+1), .large-up-7 > .columns:nth-of-type(7n+1) {
    clear: both;
  }
  .large-up-7 > .column:last-child, .large-up-7 > .columns:last-child {
    float: left;
  }

  .large-up-8 > .column, .large-up-8 > .columns {
    float: left;
    width: 12.5%;
  }
  .large-up-8 > .column:nth-of-type(1n), .large-up-8 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-8 > .column:nth-of-type(8n+1), .large-up-8 > .columns:nth-of-type(8n+1) {
    clear: both;
  }
  .large-up-8 > .column:last-child, .large-up-8 > .columns:last-child {
    float: left;
  }

  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .large-collapse .row {
    margin-right: 0;
    margin-left: 0;
  }

  .expanded.row .large-collapse.row {
    margin-right: 0;
    margin-left: 0;
  }

  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }

  .large-centered {
    margin-right: auto;
    margin-left: auto;
  }
  .large-centered, .large-centered:last-child:not(:first-child) {
    float: none;
    clear: both;
  }

  .large-uncentered, .large-push-0, .large-pull-0 {
    position: static;
    margin-right: 0;
    margin-left: 0;
  }
  .large-uncentered, .large-uncentered:last-child:not(:first-child), .large-push-0, .large-push-0:last-child:not(:first-child), .large-pull-0, .large-pull-0:last-child:not(:first-child) {
    float: left;
    clear: none;
  }
  .large-uncentered:last-child:not(:first-child), .large-push-0:last-child:not(:first-child), .large-pull-0:last-child:not(:first-child) {
    float: right;
  }
}
@media screen and (min-width: 90em) {
  .xlarge-1 {
    width: 8.3333333333%;
  }

  .xlarge-push-1 {
    position: relative;
    left: 8.3333333333%;
  }

  .xlarge-pull-1 {
    position: relative;
    left: -8.3333333333%;
  }

  .xlarge-offset-0 {
    margin-left: 0%;
  }

  .xlarge-2 {
    width: 16.6666666667%;
  }

  .xlarge-push-2 {
    position: relative;
    left: 16.6666666667%;
  }

  .xlarge-pull-2 {
    position: relative;
    left: -16.6666666667%;
  }

  .xlarge-offset-1 {
    margin-left: 8.3333333333%;
  }

  .xlarge-3 {
    width: 25%;
  }

  .xlarge-push-3 {
    position: relative;
    left: 25%;
  }

  .xlarge-pull-3 {
    position: relative;
    left: -25%;
  }

  .xlarge-offset-2 {
    margin-left: 16.6666666667%;
  }

  .xlarge-4 {
    width: 33.3333333333%;
  }

  .xlarge-push-4 {
    position: relative;
    left: 33.3333333333%;
  }

  .xlarge-pull-4 {
    position: relative;
    left: -33.3333333333%;
  }

  .xlarge-offset-3 {
    margin-left: 25%;
  }

  .xlarge-5 {
    width: 41.6666666667%;
  }

  .xlarge-push-5 {
    position: relative;
    left: 41.6666666667%;
  }

  .xlarge-pull-5 {
    position: relative;
    left: -41.6666666667%;
  }

  .xlarge-offset-4 {
    margin-left: 33.3333333333%;
  }

  .xlarge-6 {
    width: 50%;
  }

  .xlarge-push-6 {
    position: relative;
    left: 50%;
  }

  .xlarge-pull-6 {
    position: relative;
    left: -50%;
  }

  .xlarge-offset-5 {
    margin-left: 41.6666666667%;
  }

  .xlarge-7 {
    width: 58.3333333333%;
  }

  .xlarge-push-7 {
    position: relative;
    left: 58.3333333333%;
  }

  .xlarge-pull-7 {
    position: relative;
    left: -58.3333333333%;
  }

  .xlarge-offset-6 {
    margin-left: 50%;
  }

  .xlarge-8 {
    width: 66.6666666667%;
  }

  .xlarge-push-8 {
    position: relative;
    left: 66.6666666667%;
  }

  .xlarge-pull-8 {
    position: relative;
    left: -66.6666666667%;
  }

  .xlarge-offset-7 {
    margin-left: 58.3333333333%;
  }

  .xlarge-9 {
    width: 75%;
  }

  .xlarge-push-9 {
    position: relative;
    left: 75%;
  }

  .xlarge-pull-9 {
    position: relative;
    left: -75%;
  }

  .xlarge-offset-8 {
    margin-left: 66.6666666667%;
  }

  .xlarge-10 {
    width: 83.3333333333%;
  }

  .xlarge-push-10 {
    position: relative;
    left: 83.3333333333%;
  }

  .xlarge-pull-10 {
    position: relative;
    left: -83.3333333333%;
  }

  .xlarge-offset-9 {
    margin-left: 75%;
  }

  .xlarge-11 {
    width: 91.6666666667%;
  }

  .xlarge-push-11 {
    position: relative;
    left: 91.6666666667%;
  }

  .xlarge-pull-11 {
    position: relative;
    left: -91.6666666667%;
  }

  .xlarge-offset-10 {
    margin-left: 83.3333333333%;
  }

  .xlarge-12 {
    width: 100%;
  }

  .xlarge-offset-11 {
    margin-left: 91.6666666667%;
  }

  .xlarge-up-1 > .column, .xlarge-up-1 > .columns {
    float: left;
    width: 100%;
  }
  .xlarge-up-1 > .column:nth-of-type(1n), .xlarge-up-1 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .xlarge-up-1 > .column:nth-of-type(1n+1), .xlarge-up-1 > .columns:nth-of-type(1n+1) {
    clear: both;
  }
  .xlarge-up-1 > .column:last-child, .xlarge-up-1 > .columns:last-child {
    float: left;
  }

  .xlarge-up-2 > .column, .xlarge-up-2 > .columns {
    float: left;
    width: 50%;
  }
  .xlarge-up-2 > .column:nth-of-type(1n), .xlarge-up-2 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .xlarge-up-2 > .column:nth-of-type(2n+1), .xlarge-up-2 > .columns:nth-of-type(2n+1) {
    clear: both;
  }
  .xlarge-up-2 > .column:last-child, .xlarge-up-2 > .columns:last-child {
    float: left;
  }

  .xlarge-up-3 > .column, .xlarge-up-3 > .columns {
    float: left;
    width: 33.3333333333%;
  }
  .xlarge-up-3 > .column:nth-of-type(1n), .xlarge-up-3 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .xlarge-up-3 > .column:nth-of-type(3n+1), .xlarge-up-3 > .columns:nth-of-type(3n+1) {
    clear: both;
  }
  .xlarge-up-3 > .column:last-child, .xlarge-up-3 > .columns:last-child {
    float: left;
  }

  .xlarge-up-4 > .column, .xlarge-up-4 > .columns {
    float: left;
    width: 25%;
  }
  .xlarge-up-4 > .column:nth-of-type(1n), .xlarge-up-4 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .xlarge-up-4 > .column:nth-of-type(4n+1), .xlarge-up-4 > .columns:nth-of-type(4n+1) {
    clear: both;
  }
  .xlarge-up-4 > .column:last-child, .xlarge-up-4 > .columns:last-child {
    float: left;
  }

  .xlarge-up-5 > .column, .xlarge-up-5 > .columns {
    float: left;
    width: 20%;
  }
  .xlarge-up-5 > .column:nth-of-type(1n), .xlarge-up-5 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .xlarge-up-5 > .column:nth-of-type(5n+1), .xlarge-up-5 > .columns:nth-of-type(5n+1) {
    clear: both;
  }
  .xlarge-up-5 > .column:last-child, .xlarge-up-5 > .columns:last-child {
    float: left;
  }

  .xlarge-up-6 > .column, .xlarge-up-6 > .columns {
    float: left;
    width: 16.6666666667%;
  }
  .xlarge-up-6 > .column:nth-of-type(1n), .xlarge-up-6 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .xlarge-up-6 > .column:nth-of-type(6n+1), .xlarge-up-6 > .columns:nth-of-type(6n+1) {
    clear: both;
  }
  .xlarge-up-6 > .column:last-child, .xlarge-up-6 > .columns:last-child {
    float: left;
  }

  .xlarge-up-7 > .column, .xlarge-up-7 > .columns {
    float: left;
    width: 14.2857142857%;
  }
  .xlarge-up-7 > .column:nth-of-type(1n), .xlarge-up-7 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .xlarge-up-7 > .column:nth-of-type(7n+1), .xlarge-up-7 > .columns:nth-of-type(7n+1) {
    clear: both;
  }
  .xlarge-up-7 > .column:last-child, .xlarge-up-7 > .columns:last-child {
    float: left;
  }

  .xlarge-up-8 > .column, .xlarge-up-8 > .columns {
    float: left;
    width: 12.5%;
  }
  .xlarge-up-8 > .column:nth-of-type(1n), .xlarge-up-8 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .xlarge-up-8 > .column:nth-of-type(8n+1), .xlarge-up-8 > .columns:nth-of-type(8n+1) {
    clear: both;
  }
  .xlarge-up-8 > .column:last-child, .xlarge-up-8 > .columns:last-child {
    float: left;
  }

  .xlarge-collapse > .column, .xlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .xlarge-collapse .row {
    margin-right: 0;
    margin-left: 0;
  }

  .expanded.row .xlarge-collapse.row {
    margin-right: 0;
    margin-left: 0;
  }

  .xlarge-uncollapse > .column, .xlarge-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }

  .xlarge-centered {
    margin-right: auto;
    margin-left: auto;
  }
  .xlarge-centered, .xlarge-centered:last-child:not(:first-child) {
    float: none;
    clear: both;
  }

  .xlarge-uncentered, .xlarge-push-0, .xlarge-pull-0 {
    position: static;
    margin-right: 0;
    margin-left: 0;
  }
  .xlarge-uncentered, .xlarge-uncentered:last-child:not(:first-child), .xlarge-push-0, .xlarge-push-0:last-child:not(:first-child), .xlarge-pull-0, .xlarge-pull-0:last-child:not(:first-child) {
    float: left;
    clear: none;
  }
  .xlarge-uncentered:last-child:not(:first-child), .xlarge-push-0:last-child:not(:first-child), .xlarge-pull-0:last-child:not(:first-child) {
    float: right;
  }
}
@media screen and (min-width: 112.5em) {
  .xxlarge-1 {
    width: 8.3333333333%;
  }

  .xxlarge-push-1 {
    position: relative;
    left: 8.3333333333%;
  }

  .xxlarge-pull-1 {
    position: relative;
    left: -8.3333333333%;
  }

  .xxlarge-offset-0 {
    margin-left: 0%;
  }

  .xxlarge-2 {
    width: 16.6666666667%;
  }

  .xxlarge-push-2 {
    position: relative;
    left: 16.6666666667%;
  }

  .xxlarge-pull-2 {
    position: relative;
    left: -16.6666666667%;
  }

  .xxlarge-offset-1 {
    margin-left: 8.3333333333%;
  }

  .xxlarge-3 {
    width: 25%;
  }

  .xxlarge-push-3 {
    position: relative;
    left: 25%;
  }

  .xxlarge-pull-3 {
    position: relative;
    left: -25%;
  }

  .xxlarge-offset-2 {
    margin-left: 16.6666666667%;
  }

  .xxlarge-4 {
    width: 33.3333333333%;
  }

  .xxlarge-push-4 {
    position: relative;
    left: 33.3333333333%;
  }

  .xxlarge-pull-4 {
    position: relative;
    left: -33.3333333333%;
  }

  .xxlarge-offset-3 {
    margin-left: 25%;
  }

  .xxlarge-5 {
    width: 41.6666666667%;
  }

  .xxlarge-push-5 {
    position: relative;
    left: 41.6666666667%;
  }

  .xxlarge-pull-5 {
    position: relative;
    left: -41.6666666667%;
  }

  .xxlarge-offset-4 {
    margin-left: 33.3333333333%;
  }

  .xxlarge-6 {
    width: 50%;
  }

  .xxlarge-push-6 {
    position: relative;
    left: 50%;
  }

  .xxlarge-pull-6 {
    position: relative;
    left: -50%;
  }

  .xxlarge-offset-5 {
    margin-left: 41.6666666667%;
  }

  .xxlarge-7 {
    width: 58.3333333333%;
  }

  .xxlarge-push-7 {
    position: relative;
    left: 58.3333333333%;
  }

  .xxlarge-pull-7 {
    position: relative;
    left: -58.3333333333%;
  }

  .xxlarge-offset-6 {
    margin-left: 50%;
  }

  .xxlarge-8 {
    width: 66.6666666667%;
  }

  .xxlarge-push-8 {
    position: relative;
    left: 66.6666666667%;
  }

  .xxlarge-pull-8 {
    position: relative;
    left: -66.6666666667%;
  }

  .xxlarge-offset-7 {
    margin-left: 58.3333333333%;
  }

  .xxlarge-9 {
    width: 75%;
  }

  .xxlarge-push-9 {
    position: relative;
    left: 75%;
  }

  .xxlarge-pull-9 {
    position: relative;
    left: -75%;
  }

  .xxlarge-offset-8 {
    margin-left: 66.6666666667%;
  }

  .xxlarge-10 {
    width: 83.3333333333%;
  }

  .xxlarge-push-10 {
    position: relative;
    left: 83.3333333333%;
  }

  .xxlarge-pull-10 {
    position: relative;
    left: -83.3333333333%;
  }

  .xxlarge-offset-9 {
    margin-left: 75%;
  }

  .xxlarge-11 {
    width: 91.6666666667%;
  }

  .xxlarge-push-11 {
    position: relative;
    left: 91.6666666667%;
  }

  .xxlarge-pull-11 {
    position: relative;
    left: -91.6666666667%;
  }

  .xxlarge-offset-10 {
    margin-left: 83.3333333333%;
  }

  .xxlarge-12 {
    width: 100%;
  }

  .xxlarge-offset-11 {
    margin-left: 91.6666666667%;
  }

  .xxlarge-up-1 > .column, .xxlarge-up-1 > .columns {
    float: left;
    width: 100%;
  }
  .xxlarge-up-1 > .column:nth-of-type(1n), .xxlarge-up-1 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .xxlarge-up-1 > .column:nth-of-type(1n+1), .xxlarge-up-1 > .columns:nth-of-type(1n+1) {
    clear: both;
  }
  .xxlarge-up-1 > .column:last-child, .xxlarge-up-1 > .columns:last-child {
    float: left;
  }

  .xxlarge-up-2 > .column, .xxlarge-up-2 > .columns {
    float: left;
    width: 50%;
  }
  .xxlarge-up-2 > .column:nth-of-type(1n), .xxlarge-up-2 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .xxlarge-up-2 > .column:nth-of-type(2n+1), .xxlarge-up-2 > .columns:nth-of-type(2n+1) {
    clear: both;
  }
  .xxlarge-up-2 > .column:last-child, .xxlarge-up-2 > .columns:last-child {
    float: left;
  }

  .xxlarge-up-3 > .column, .xxlarge-up-3 > .columns {
    float: left;
    width: 33.3333333333%;
  }
  .xxlarge-up-3 > .column:nth-of-type(1n), .xxlarge-up-3 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .xxlarge-up-3 > .column:nth-of-type(3n+1), .xxlarge-up-3 > .columns:nth-of-type(3n+1) {
    clear: both;
  }
  .xxlarge-up-3 > .column:last-child, .xxlarge-up-3 > .columns:last-child {
    float: left;
  }

  .xxlarge-up-4 > .column, .xxlarge-up-4 > .columns {
    float: left;
    width: 25%;
  }
  .xxlarge-up-4 > .column:nth-of-type(1n), .xxlarge-up-4 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .xxlarge-up-4 > .column:nth-of-type(4n+1), .xxlarge-up-4 > .columns:nth-of-type(4n+1) {
    clear: both;
  }
  .xxlarge-up-4 > .column:last-child, .xxlarge-up-4 > .columns:last-child {
    float: left;
  }

  .xxlarge-up-5 > .column, .xxlarge-up-5 > .columns {
    float: left;
    width: 20%;
  }
  .xxlarge-up-5 > .column:nth-of-type(1n), .xxlarge-up-5 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .xxlarge-up-5 > .column:nth-of-type(5n+1), .xxlarge-up-5 > .columns:nth-of-type(5n+1) {
    clear: both;
  }
  .xxlarge-up-5 > .column:last-child, .xxlarge-up-5 > .columns:last-child {
    float: left;
  }

  .xxlarge-up-6 > .column, .xxlarge-up-6 > .columns {
    float: left;
    width: 16.6666666667%;
  }
  .xxlarge-up-6 > .column:nth-of-type(1n), .xxlarge-up-6 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .xxlarge-up-6 > .column:nth-of-type(6n+1), .xxlarge-up-6 > .columns:nth-of-type(6n+1) {
    clear: both;
  }
  .xxlarge-up-6 > .column:last-child, .xxlarge-up-6 > .columns:last-child {
    float: left;
  }

  .xxlarge-up-7 > .column, .xxlarge-up-7 > .columns {
    float: left;
    width: 14.2857142857%;
  }
  .xxlarge-up-7 > .column:nth-of-type(1n), .xxlarge-up-7 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .xxlarge-up-7 > .column:nth-of-type(7n+1), .xxlarge-up-7 > .columns:nth-of-type(7n+1) {
    clear: both;
  }
  .xxlarge-up-7 > .column:last-child, .xxlarge-up-7 > .columns:last-child {
    float: left;
  }

  .xxlarge-up-8 > .column, .xxlarge-up-8 > .columns {
    float: left;
    width: 12.5%;
  }
  .xxlarge-up-8 > .column:nth-of-type(1n), .xxlarge-up-8 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .xxlarge-up-8 > .column:nth-of-type(8n+1), .xxlarge-up-8 > .columns:nth-of-type(8n+1) {
    clear: both;
  }
  .xxlarge-up-8 > .column:last-child, .xxlarge-up-8 > .columns:last-child {
    float: left;
  }

  .xxlarge-collapse > .column, .xxlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .xxlarge-collapse .row {
    margin-right: 0;
    margin-left: 0;
  }

  .expanded.row .xxlarge-collapse.row {
    margin-right: 0;
    margin-left: 0;
  }

  .xxlarge-uncollapse > .column, .xxlarge-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }

  .xxlarge-centered {
    margin-right: auto;
    margin-left: auto;
  }
  .xxlarge-centered, .xxlarge-centered:last-child:not(:first-child) {
    float: none;
    clear: both;
  }

  .xxlarge-uncentered, .xxlarge-push-0, .xxlarge-pull-0 {
    position: static;
    margin-right: 0;
    margin-left: 0;
  }
  .xxlarge-uncentered, .xxlarge-uncentered:last-child:not(:first-child), .xxlarge-push-0, .xxlarge-push-0:last-child:not(:first-child), .xxlarge-pull-0, .xxlarge-pull-0:last-child:not(:first-child) {
    float: left;
    clear: none;
  }
  .xxlarge-uncentered:last-child:not(:first-child), .xxlarge-push-0:last-child:not(:first-child), .xxlarge-pull-0:last-child:not(:first-child) {
    float: right;
  }
}
.column-block {
  margin-bottom: 1.25rem;
}
.column-block > :last-child {
  margin-bottom: 0;
}
@media print, screen and (min-width: 48em) {
  .column-block {
    margin-bottom: 1.875rem;
  }
  .column-block > :last-child {
    margin-bottom: 0;
  }
}

/* or foundation-flex-grid (but don't include both) //*/
.row {
  max-width: 1440px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
}
.row .row {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}
@media print, screen and (min-width: 20em) {
  .row .row {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
  }
}
@media print, screen and (min-width: 48em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media print, screen and (min-width: 60em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media print, screen and (min-width: 80em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media screen and (min-width: 112.5em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
.row .row.collapse {
  margin-right: 0;
  margin-left: 0;
}
.row.expanded {
  max-width: none;
}
.row.expanded .row {
  margin-right: auto;
  margin-left: auto;
}
.row:not(.expanded) .row {
  max-width: none;
}
.row.collapse > .column, .row.collapse > .columns {
  padding-right: 0;
  padding-left: 0;
}
.row.is-collapse-child, .row.collapse > .column > .row, .row.collapse > .columns > .row {
  margin-right: 0;
  margin-left: 0;
}

.column, .columns {
  flex: 1 1 0px;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  min-width: 0;
}
@media print, screen and (min-width: 48em) {
  .column, .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.column.row.row, .row.row.columns {
  float: none;
  display: block;
}

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0;
}

.small-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.small-offset-0 {
  margin-left: 0%;
}

.small-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.small-offset-1 {
  margin-left: 8.3333333333%;
}

.small-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.small-offset-2 {
  margin-left: 16.6666666667%;
}

.small-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.small-offset-3 {
  margin-left: 25%;
}

.small-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.small-offset-4 {
  margin-left: 33.3333333333%;
}

.small-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.small-offset-5 {
  margin-left: 41.6666666667%;
}

.small-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.small-offset-6 {
  margin-left: 50%;
}

.small-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.small-offset-7 {
  margin-left: 58.3333333333%;
}

.small-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.small-offset-8 {
  margin-left: 66.6666666667%;
}

.small-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.small-offset-9 {
  margin-left: 75%;
}

.small-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.small-offset-10 {
  margin-left: 83.3333333333%;
}

.small-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.small-offset-11 {
  margin-left: 91.6666666667%;
}

.small-up-1 {
  flex-wrap: wrap;
}
.small-up-1 > .column, .small-up-1 > .columns {
  flex: 0 0 100%;
  max-width: 100%;
}

.small-up-2 {
  flex-wrap: wrap;
}
.small-up-2 > .column, .small-up-2 > .columns {
  flex: 0 0 50%;
  max-width: 50%;
}

.small-up-3 {
  flex-wrap: wrap;
}
.small-up-3 > .column, .small-up-3 > .columns {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.small-up-4 {
  flex-wrap: wrap;
}
.small-up-4 > .column, .small-up-4 > .columns {
  flex: 0 0 25%;
  max-width: 25%;
}

.small-up-5 {
  flex-wrap: wrap;
}
.small-up-5 > .column, .small-up-5 > .columns {
  flex: 0 0 20%;
  max-width: 20%;
}

.small-up-6 {
  flex-wrap: wrap;
}
.small-up-6 > .column, .small-up-6 > .columns {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.small-up-7 {
  flex-wrap: wrap;
}
.small-up-7 > .column, .small-up-7 > .columns {
  flex: 0 0 14.2857142857%;
  max-width: 14.2857142857%;
}

.small-up-8 {
  flex-wrap: wrap;
}
.small-up-8 > .column, .small-up-8 > .columns {
  flex: 0 0 12.5%;
  max-width: 12.5%;
}

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0;
}

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}

@media print, screen and (min-width: 20em) {
  .smedium-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .smedium-offset-0 {
    margin-left: 0%;
  }

  .smedium-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .smedium-offset-1 {
    margin-left: 8.3333333333%;
  }

  .smedium-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .smedium-offset-2 {
    margin-left: 16.6666666667%;
  }

  .smedium-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .smedium-offset-3 {
    margin-left: 25%;
  }

  .smedium-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .smedium-offset-4 {
    margin-left: 33.3333333333%;
  }

  .smedium-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .smedium-offset-5 {
    margin-left: 41.6666666667%;
  }

  .smedium-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .smedium-offset-6 {
    margin-left: 50%;
  }

  .smedium-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .smedium-offset-7 {
    margin-left: 58.3333333333%;
  }

  .smedium-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .smedium-offset-8 {
    margin-left: 66.6666666667%;
  }

  .smedium-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .smedium-offset-9 {
    margin-left: 75%;
  }

  .smedium-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .smedium-offset-10 {
    margin-left: 83.3333333333%;
  }

  .smedium-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .smedium-offset-11 {
    margin-left: 91.6666666667%;
  }

  .smedium-up-1 {
    flex-wrap: wrap;
  }
  .smedium-up-1 > .column, .smedium-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .smedium-up-2 {
    flex-wrap: wrap;
  }
  .smedium-up-2 > .column, .smedium-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .smedium-up-3 {
    flex-wrap: wrap;
  }
  .smedium-up-3 > .column, .smedium-up-3 > .columns {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .smedium-up-4 {
    flex-wrap: wrap;
  }
  .smedium-up-4 > .column, .smedium-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .smedium-up-5 {
    flex-wrap: wrap;
  }
  .smedium-up-5 > .column, .smedium-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .smedium-up-6 {
    flex-wrap: wrap;
  }
  .smedium-up-6 > .column, .smedium-up-6 > .columns {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .smedium-up-7 {
    flex-wrap: wrap;
  }
  .smedium-up-7 > .column, .smedium-up-7 > .columns {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }

  .smedium-up-8 {
    flex-wrap: wrap;
  }
  .smedium-up-8 > .column, .smedium-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media print, screen and (min-width: 20em) and (min-width: 20em) {
  .smedium-expand {
    flex: 1 1 0px;
  }
}
.row.smedium-unstack > .column, .row.smedium-unstack > .columns {
  flex: 0 0 100%;
}
@media print, screen and (min-width: 20em) {
  .row.smedium-unstack > .column, .row.smedium-unstack > .columns {
    flex: 1 1 0px;
  }
}

@media print, screen and (min-width: 20em) {
  .smedium-collapse > .column, .smedium-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
}
@media print, screen and (min-width: 20em) {
  .smedium-uncollapse > .column, .smedium-uncollapse > .columns {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
  }
}
@media print, screen and (min-width: 48em) {
  .medium-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .medium-offset-0 {
    margin-left: 0%;
  }

  .medium-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .medium-offset-1 {
    margin-left: 8.3333333333%;
  }

  .medium-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .medium-offset-2 {
    margin-left: 16.6666666667%;
  }

  .medium-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .medium-offset-3 {
    margin-left: 25%;
  }

  .medium-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .medium-offset-4 {
    margin-left: 33.3333333333%;
  }

  .medium-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .medium-offset-5 {
    margin-left: 41.6666666667%;
  }

  .medium-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .medium-offset-6 {
    margin-left: 50%;
  }

  .medium-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .medium-offset-7 {
    margin-left: 58.3333333333%;
  }

  .medium-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .medium-offset-8 {
    margin-left: 66.6666666667%;
  }

  .medium-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .medium-offset-9 {
    margin-left: 75%;
  }

  .medium-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .medium-offset-10 {
    margin-left: 83.3333333333%;
  }

  .medium-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .medium-offset-11 {
    margin-left: 91.6666666667%;
  }

  .medium-up-1 {
    flex-wrap: wrap;
  }
  .medium-up-1 > .column, .medium-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .medium-up-2 {
    flex-wrap: wrap;
  }
  .medium-up-2 > .column, .medium-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .medium-up-3 {
    flex-wrap: wrap;
  }
  .medium-up-3 > .column, .medium-up-3 > .columns {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .medium-up-4 {
    flex-wrap: wrap;
  }
  .medium-up-4 > .column, .medium-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .medium-up-5 {
    flex-wrap: wrap;
  }
  .medium-up-5 > .column, .medium-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .medium-up-6 {
    flex-wrap: wrap;
  }
  .medium-up-6 > .column, .medium-up-6 > .columns {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .medium-up-7 {
    flex-wrap: wrap;
  }
  .medium-up-7 > .column, .medium-up-7 > .columns {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }

  .medium-up-8 {
    flex-wrap: wrap;
  }
  .medium-up-8 > .column, .medium-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media print, screen and (min-width: 48em) and (min-width: 48em) {
  .medium-expand {
    flex: 1 1 0px;
  }
}
.row.medium-unstack > .column, .row.medium-unstack > .columns {
  flex: 0 0 100%;
}
@media print, screen and (min-width: 48em) {
  .row.medium-unstack > .column, .row.medium-unstack > .columns {
    flex: 1 1 0px;
  }
}

@media print, screen and (min-width: 48em) {
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
}
@media print, screen and (min-width: 48em) {
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 60em) {
  .mlarge-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .mlarge-offset-0 {
    margin-left: 0%;
  }

  .mlarge-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .mlarge-offset-1 {
    margin-left: 8.3333333333%;
  }

  .mlarge-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .mlarge-offset-2 {
    margin-left: 16.6666666667%;
  }

  .mlarge-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .mlarge-offset-3 {
    margin-left: 25%;
  }

  .mlarge-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .mlarge-offset-4 {
    margin-left: 33.3333333333%;
  }

  .mlarge-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .mlarge-offset-5 {
    margin-left: 41.6666666667%;
  }

  .mlarge-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .mlarge-offset-6 {
    margin-left: 50%;
  }

  .mlarge-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .mlarge-offset-7 {
    margin-left: 58.3333333333%;
  }

  .mlarge-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .mlarge-offset-8 {
    margin-left: 66.6666666667%;
  }

  .mlarge-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .mlarge-offset-9 {
    margin-left: 75%;
  }

  .mlarge-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .mlarge-offset-10 {
    margin-left: 83.3333333333%;
  }

  .mlarge-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .mlarge-offset-11 {
    margin-left: 91.6666666667%;
  }

  .mlarge-up-1 {
    flex-wrap: wrap;
  }
  .mlarge-up-1 > .column, .mlarge-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .mlarge-up-2 {
    flex-wrap: wrap;
  }
  .mlarge-up-2 > .column, .mlarge-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .mlarge-up-3 {
    flex-wrap: wrap;
  }
  .mlarge-up-3 > .column, .mlarge-up-3 > .columns {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .mlarge-up-4 {
    flex-wrap: wrap;
  }
  .mlarge-up-4 > .column, .mlarge-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .mlarge-up-5 {
    flex-wrap: wrap;
  }
  .mlarge-up-5 > .column, .mlarge-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .mlarge-up-6 {
    flex-wrap: wrap;
  }
  .mlarge-up-6 > .column, .mlarge-up-6 > .columns {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .mlarge-up-7 {
    flex-wrap: wrap;
  }
  .mlarge-up-7 > .column, .mlarge-up-7 > .columns {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }

  .mlarge-up-8 {
    flex-wrap: wrap;
  }
  .mlarge-up-8 > .column, .mlarge-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media print, screen and (min-width: 60em) and (min-width: 60em) {
  .mlarge-expand {
    flex: 1 1 0px;
  }
}
.row.mlarge-unstack > .column, .row.mlarge-unstack > .columns {
  flex: 0 0 100%;
}
@media print, screen and (min-width: 60em) {
  .row.mlarge-unstack > .column, .row.mlarge-unstack > .columns {
    flex: 1 1 0px;
  }
}

@media print, screen and (min-width: 60em) {
  .mlarge-collapse > .column, .mlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
}
@media print, screen and (min-width: 60em) {
  .mlarge-uncollapse > .column, .mlarge-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media print, screen and (min-width: 80em) {
  .large-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .large-offset-0 {
    margin-left: 0%;
  }

  .large-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .large-offset-1 {
    margin-left: 8.3333333333%;
  }

  .large-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .large-offset-2 {
    margin-left: 16.6666666667%;
  }

  .large-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .large-offset-3 {
    margin-left: 25%;
  }

  .large-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .large-offset-4 {
    margin-left: 33.3333333333%;
  }

  .large-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .large-offset-5 {
    margin-left: 41.6666666667%;
  }

  .large-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .large-offset-6 {
    margin-left: 50%;
  }

  .large-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .large-offset-7 {
    margin-left: 58.3333333333%;
  }

  .large-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .large-offset-8 {
    margin-left: 66.6666666667%;
  }

  .large-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .large-offset-9 {
    margin-left: 75%;
  }

  .large-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .large-offset-10 {
    margin-left: 83.3333333333%;
  }

  .large-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .large-offset-11 {
    margin-left: 91.6666666667%;
  }

  .large-up-1 {
    flex-wrap: wrap;
  }
  .large-up-1 > .column, .large-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .large-up-2 {
    flex-wrap: wrap;
  }
  .large-up-2 > .column, .large-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .large-up-3 {
    flex-wrap: wrap;
  }
  .large-up-3 > .column, .large-up-3 > .columns {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .large-up-4 {
    flex-wrap: wrap;
  }
  .large-up-4 > .column, .large-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .large-up-5 {
    flex-wrap: wrap;
  }
  .large-up-5 > .column, .large-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .large-up-6 {
    flex-wrap: wrap;
  }
  .large-up-6 > .column, .large-up-6 > .columns {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .large-up-7 {
    flex-wrap: wrap;
  }
  .large-up-7 > .column, .large-up-7 > .columns {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }

  .large-up-8 {
    flex-wrap: wrap;
  }
  .large-up-8 > .column, .large-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media print, screen and (min-width: 80em) and (min-width: 80em) {
  .large-expand {
    flex: 1 1 0px;
  }
}
.row.large-unstack > .column, .row.large-unstack > .columns {
  flex: 0 0 100%;
}
@media print, screen and (min-width: 80em) {
  .row.large-unstack > .column, .row.large-unstack > .columns {
    flex: 1 1 0px;
  }
}

@media print, screen and (min-width: 80em) {
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
}
@media print, screen and (min-width: 80em) {
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 90em) {
  .xlarge-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .xlarge-offset-0 {
    margin-left: 0%;
  }

  .xlarge-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .xlarge-offset-1 {
    margin-left: 8.3333333333%;
  }

  .xlarge-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .xlarge-offset-2 {
    margin-left: 16.6666666667%;
  }

  .xlarge-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .xlarge-offset-3 {
    margin-left: 25%;
  }

  .xlarge-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .xlarge-offset-4 {
    margin-left: 33.3333333333%;
  }

  .xlarge-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .xlarge-offset-5 {
    margin-left: 41.6666666667%;
  }

  .xlarge-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .xlarge-offset-6 {
    margin-left: 50%;
  }

  .xlarge-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .xlarge-offset-7 {
    margin-left: 58.3333333333%;
  }

  .xlarge-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .xlarge-offset-8 {
    margin-left: 66.6666666667%;
  }

  .xlarge-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .xlarge-offset-9 {
    margin-left: 75%;
  }

  .xlarge-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .xlarge-offset-10 {
    margin-left: 83.3333333333%;
  }

  .xlarge-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .xlarge-offset-11 {
    margin-left: 91.6666666667%;
  }

  .xlarge-up-1 {
    flex-wrap: wrap;
  }
  .xlarge-up-1 > .column, .xlarge-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .xlarge-up-2 {
    flex-wrap: wrap;
  }
  .xlarge-up-2 > .column, .xlarge-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .xlarge-up-3 {
    flex-wrap: wrap;
  }
  .xlarge-up-3 > .column, .xlarge-up-3 > .columns {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .xlarge-up-4 {
    flex-wrap: wrap;
  }
  .xlarge-up-4 > .column, .xlarge-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .xlarge-up-5 {
    flex-wrap: wrap;
  }
  .xlarge-up-5 > .column, .xlarge-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .xlarge-up-6 {
    flex-wrap: wrap;
  }
  .xlarge-up-6 > .column, .xlarge-up-6 > .columns {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .xlarge-up-7 {
    flex-wrap: wrap;
  }
  .xlarge-up-7 > .column, .xlarge-up-7 > .columns {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }

  .xlarge-up-8 {
    flex-wrap: wrap;
  }
  .xlarge-up-8 > .column, .xlarge-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media screen and (min-width: 90em) and (min-width: 90em) {
  .xlarge-expand {
    flex: 1 1 0px;
  }
}
.row.xlarge-unstack > .column, .row.xlarge-unstack > .columns {
  flex: 0 0 100%;
}
@media screen and (min-width: 90em) {
  .row.xlarge-unstack > .column, .row.xlarge-unstack > .columns {
    flex: 1 1 0px;
  }
}

@media screen and (min-width: 90em) {
  .xlarge-collapse > .column, .xlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
}
@media screen and (min-width: 90em) {
  .xlarge-uncollapse > .column, .xlarge-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 112.5em) {
  .xxlarge-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .xxlarge-offset-0 {
    margin-left: 0%;
  }

  .xxlarge-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .xxlarge-offset-1 {
    margin-left: 8.3333333333%;
  }

  .xxlarge-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .xxlarge-offset-2 {
    margin-left: 16.6666666667%;
  }

  .xxlarge-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .xxlarge-offset-3 {
    margin-left: 25%;
  }

  .xxlarge-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .xxlarge-offset-4 {
    margin-left: 33.3333333333%;
  }

  .xxlarge-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .xxlarge-offset-5 {
    margin-left: 41.6666666667%;
  }

  .xxlarge-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .xxlarge-offset-6 {
    margin-left: 50%;
  }

  .xxlarge-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .xxlarge-offset-7 {
    margin-left: 58.3333333333%;
  }

  .xxlarge-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .xxlarge-offset-8 {
    margin-left: 66.6666666667%;
  }

  .xxlarge-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .xxlarge-offset-9 {
    margin-left: 75%;
  }

  .xxlarge-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .xxlarge-offset-10 {
    margin-left: 83.3333333333%;
  }

  .xxlarge-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .xxlarge-offset-11 {
    margin-left: 91.6666666667%;
  }

  .xxlarge-up-1 {
    flex-wrap: wrap;
  }
  .xxlarge-up-1 > .column, .xxlarge-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .xxlarge-up-2 {
    flex-wrap: wrap;
  }
  .xxlarge-up-2 > .column, .xxlarge-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .xxlarge-up-3 {
    flex-wrap: wrap;
  }
  .xxlarge-up-3 > .column, .xxlarge-up-3 > .columns {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .xxlarge-up-4 {
    flex-wrap: wrap;
  }
  .xxlarge-up-4 > .column, .xxlarge-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .xxlarge-up-5 {
    flex-wrap: wrap;
  }
  .xxlarge-up-5 > .column, .xxlarge-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .xxlarge-up-6 {
    flex-wrap: wrap;
  }
  .xxlarge-up-6 > .column, .xxlarge-up-6 > .columns {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .xxlarge-up-7 {
    flex-wrap: wrap;
  }
  .xxlarge-up-7 > .column, .xxlarge-up-7 > .columns {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }

  .xxlarge-up-8 {
    flex-wrap: wrap;
  }
  .xxlarge-up-8 > .column, .xxlarge-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media screen and (min-width: 112.5em) and (min-width: 112.5em) {
  .xxlarge-expand {
    flex: 1 1 0px;
  }
}
.row.xxlarge-unstack > .column, .row.xxlarge-unstack > .columns {
  flex: 0 0 100%;
}
@media screen and (min-width: 112.5em) {
  .row.xxlarge-unstack > .column, .row.xxlarge-unstack > .columns {
    flex: 1 1 0px;
  }
}

@media screen and (min-width: 112.5em) {
  .xxlarge-collapse > .column, .xxlarge-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
}
@media screen and (min-width: 112.5em) {
  .xxlarge-uncollapse > .column, .xxlarge-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.shrink {
  flex: 0 0 auto;
  max-width: 100%;
}

.column-block {
  margin-bottom: 1.25rem;
}
.column-block > :last-child {
  margin-bottom: 0;
}
@media print, screen and (min-width: 48em) {
  .column-block {
    margin-bottom: 1.875rem;
  }
  .column-block > :last-child {
    margin-bottom: 0;
  }
}

/*--------------------------------------------------------------
4.0 Components
--------------------------------------------------------------*/