.padding-left-0-25rem { padding-left: 0.25rem; }
.padding-left-0-5rem { padding-left: 0.5rem; }
.padding-left-1rem { padding-left: 1rem; }
.padding-left-1-5rem { padding-left: 1.5rem; }
.padding-left-2rem { padding-left: 2rem; }
.padding-left-3rem { padding-left: 3rem; }
.padding-left-4rem { padding-left: 4rem; }
.padding-left-5rem { padding-left: 5rem; }
.padding-right-0-25rem { padding-right: 0.25rem; }
.padding-right-0-5rem { padding-right: 0.5rem; }
.padding-right-1rem { padding-right: 1rem; }
.padding-right-1-5rem { padding-right: 1.5rem; }
.padding-right-2rem { padding-right: 2rem; }
.padding-right-3rem { padding-right: 3rem; }
.padding-right-4rem { padding-right: 4rem; }
.padding-right-5rem { padding-right: 5rem; }
